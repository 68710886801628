import { GetServerSidePropsContext } from 'next/types';
import { getPage } from 'evcharging/services/cmsClient';
import { getDictionary } from 'i18n';
import { PageInterface } from 'evcharging/services/types';
import {I18nPage} from "i18n/types/pages.enum";

export interface ENVIRONMENT {
  IS_PRODUCTION: boolean;
  // E5
  ENVIRONMENT: string;
  SITE_URL: string;
  API_BASE_URL: string;
  ASSET_URL: string;
  RETAIL_LEAD_TO: string;
  CONTACT_EMAIL_TO: string;
  SCHEDULE_EMAIL_TO: string;
  BLOG_EMAIL_TO: string;
  PRICING_EMAIL_TO: string;
  GOOGLE_MAP_KEY: string;
  CMS_API_BASE_URL: string;
  SCANNING_EMAIL_TO: string;
  GTM_CONTAINER_ID: string;
  GTM_AUTH: string;
  GTM_PREVIEW: string;
  GTM_COOKIES_WIN: string;
  // EVC
  HOST_URL: string;
  POST_COMMENTER_STATIC_TOKEN: string;
  RECAPTCHA_SITE_KEY: string;
  RECAPTCHA_SECRET_KEY: string;
  // MONGO
  DATABASE_URI: string;
  DATABASE_NAME: string;
}

export let ENV: ENVIRONMENT = {
  IS_PRODUCTION: !(process.env.HOST_URL ?? '').includes('dev.'),
  // E5
  ENVIRONMENT: process.env.ENVIRONMENT,
  SITE_URL: process.env.HOST_URL ?? 'https://energy5.com',
  API_BASE_URL: process.env.API_BASE_URL,
  ASSET_URL: process.env.ASSET_URL,
  RETAIL_LEAD_TO: process.env.RETAIL_LEAD_TO,
  CONTACT_EMAIL_TO: process.env.CONTACT_EMAIL_TO,
  SCHEDULE_EMAIL_TO: process.env.SCHEDULE_EMAIL_TO,
  BLOG_EMAIL_TO: process.env.BLOG_EMAIL_TO,
  PRICING_EMAIL_TO: process.env.PRICING_EMAIL_TO,
  SCANNING_EMAIL_TO: process.env.SCANNING_EMAIL_TO,
  CMS_API_BASE_URL: process.env.CMS_API_BASE_URL,
  GOOGLE_MAP_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
  GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID,
  GTM_AUTH: process.env.GTM_AUTH ?? '',
  GTM_PREVIEW: process.env.GTM_PREVIEW ?? '',
  GTM_COOKIES_WIN: process.env.GTM_COOKIES_WIN ?? '',
  // EVC
  HOST_URL: process.env.HOST_URL ?? 'https://energy5.com',
  POST_COMMENTER_STATIC_TOKEN: process.env.POST_COMMENTER_STATIC_TOKEN ?? '',
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '',
  RECAPTCHA_SECRET_KEY: process.env.RECAPTCHA_SECRET_KEY ?? '',
  // MONGO
  DATABASE_URI: process.env.DATABASE_URI ?? '',
  DATABASE_NAME: process.env.DATABASE_NAME ?? '',
};

export const setENV = (env: ENVIRONMENT) => {
  ENV = { ...env };
};

export const withENV = (pageProps = {}) => ({ ...pageProps, ENV });

export interface StaticPageProps {
  jsonLd?: string;
  page: PageInterface;
  initialState?: any
  ENV?: ENVIRONMENT
}

export const createGetServerSideProps =
  (
    link_deprecate?: string,
    callback?: (context: GetServerSidePropsContext, locale: string) => Promise<any>,
    i18nPages?: I18nPage[]
  ) =>
  async (context: GetServerSidePropsContext): Promise<unknown> => {
    const { req, res } = context;
    const link = req.url.split('?')[0];
    const defaultLocale = String(context.defaultLocale);
    const locale = String(context.locale);
    const page = link ? await getPage(link, locale) : {};
    const i18n = await getDictionary(locale, defaultLocale, i18nPages);
    const more = callback ? await callback(context, locale) : {};

    if (more.redirect || more.notFound) {
      return more;
    }

    res.setHeader('Cache-Control', 'public, s-maxage=360, stale-while-revalidate=360');

    return {
      props: withENV({ page, i18n, ...more }),
    };
  };

export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.energy5.cp.app';
export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/energy5-ev-charging-network/id1609966908';
export const PHONE_NUMBER = '(856) 412-4645';

// export const DESKTOP_VIDEO = 'https://static.energy5.com/video/wide_desktop_compressed.mp4';
// export const DESKTOP_VIDEO = 'https://static.energy5.com/video/EVC%20video%20background.mp4';
export const DESKTOP_VIDEO = 'https://static.energy5.com/video/EV1.mp4';
export const MOBILE_VIDEO = DESKTOP_VIDEO;

// export const YOUTUBE_VIDEO = 'https://www.youtube.com/embed/-SIk6zak9lE?autoplay=1&amp;rel=0&amp';
// export const YOUTUBE_VIDEO = 'https://www.youtube.com/embed/XUo1jm5n_LU?autoplay=1&amp;rel=0&amp';
export const YOUTUBE_VIDEO = 'https://www.youtube.com/embed/c9QjgN6BSMU?autoplay=1&amp;rel=0&amp';

export const I18N_DEFAULT_LOCALE = 'en';

export const SITEMAP_PAGE_SIZE = 1000;

export const HOST_URL = 'https://energy5.com';
